@import "config/config";
@import "helpers/fontawesome"; // fa-pseudo

/**
  * Markup:
  * <button
  *   class="ui-component-tooltip position:{right*, bottom}
  *          --active" // set via JS
  *   data-tooltip={click, hover, copy}
  *   data-tooltip-message="Message to display" // required (optional for copy variant)
  *   data-tooltip-copy="Text to copy" // optional, otherwise element's text content is used
  *   data-tooltip-icon="false" // optional, to remove icon pseudo-element
  * >
  *   Copy whatever<i class="far fa-clipboard fa-fw fa:after" aria-hidden="true"></i> // optional
  * </button>
  *
  * Note: Info icon displayed via pseudo-element, and clipboard icon for copy variant.
  */
.ui-component-tooltip {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  display: inline-block;
  padding: 0;
  line-height: inherit;
  white-space: nowrap;
  cursor: pointer;
  text-decoration: underline;
  outline: none;
  border: none;
  color: $sb-green;
  background: transparent;

  .theme\:premium & {
    color: $sb-gold;
  }

  &:not([data-tooltip-icon="false"]) {
    &:not([data-tooltip="copy"])::after {
      @include fa-pseudo("\f05a"); // fa-circle-info
      margin-left: 0.75ch;
    }

    &[data-tooltip="copy"]::after {
      @include fa-pseudo("\f328"); // fa-clipboard
      margin-left: 0.75ch;
    }
  }

  i {
    color: inherit;
  }

  &.--active {
    position: relative;

    &::before {
      z-index: $z-index-modal;
      position: absolute;
      top: calc(50% - var(--y-offset, 0px));
      left: calc(100% + 0.75ch - var(--x-offset, 0px));
      transform: translateY(-50%);
      display: inline-block;
      content: attr(data-tooltip-message);
      white-space: normal;
      width: max-content;
      max-width: 200px;
      padding: 0.25ch 0.5ch;
      background: rgba($black, .95);
      color: #fff;
      border-radius: 3px;
      font-size: 14px;
      line-height: 1.5;
    }

    &.position\:bottom::before {
      top: calc(100% - var(--y-offset, 0px));
      left: calc(50% - var(--x-offset, 0px));
      transform: translateX(-50%);
    }
  }
}
